import React, { useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'

import { useIdleTimer } from 'react-idle-timer'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


function App() {

  const [state, setState] = useState('Active')

  const onIdle = () => {
    setState('Idle')
    localStorage.clear();
    sessionStorage.clear()
    window.location.href = "/#/login"
  }

  const onActive = () => {
    setState('Active')
  }

  const onAction = () => {
    setState('onAction')
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 10_0000,
    throttle: 500
  })


  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route exact path="/forgot-password" name="Forgot Password" render={(props) => <ForgotPassword {...props} />} />
          <Route exact path="/reset-password/:email/:token" name="Reset Password" render={(props) => <ResetPassword {...props} />} />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
